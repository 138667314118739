import * as React from "react";
import { HeadFC, PageProps } from "gatsby";
import NotFound from "../components/NotFound/NotFound";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";
import { Header } from "../components";

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <BaseLayout>
      <Header />
      <NotFound />
    </BaseLayout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
