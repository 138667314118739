import React from "react";
import { Grid, useMediaQuery } from "@mui/material";

import { StylesHeading, StylesSupportingText, PrimaryButton } from "./styles";

import pageNotFoundImage from "../../images/webp/page-not-found-image.webp";

const NotFound = () => {
  const mediumScreen = useMediaQuery("(max-width:900px)");

  const handleGoToHomepage = () => {
    window.location.href = window.location.origin;
  };

  return (
    <Grid
      xs={12}
      container
      flexDirection="row"
      alignItems="center"
      height="calc(100vh - 240px)"
      margin="120px 0"
      padding="0 24px"
    >
      <Grid
        xs={12}
        container
        item
        gap="48px"
        justifyContent="center"
        height="fit-content"
      >
        <Grid item width={mediumScreen ? "100%" : undefined}>
          <img
            width={mediumScreen ? "152px" : "350px"}
            src={pageNotFoundImage}
            alt={"NotFoundImage"}
          />
        </Grid>
        <Grid
          display={"flex"}
          item
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <StylesHeading item>We can’t find this page</StylesHeading>
          <StylesSupportingText item paddingTop="16px" paddingBottom="48px">
            The page you are looking for does&apos;t exist or has been moved.
          </StylesSupportingText>
          <Grid item>
            <PrimaryButton
              onClick={handleGoToHomepage}
              variant="contained"
              color="primary"
            >
              Go to homepage
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFound;
