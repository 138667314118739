import { Button, Grid, styled } from "@mui/material";

const StylesHeading = styled(Grid)`
  color: #101828;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
`;

const StylesSupportingText = styled(Grid)`
  color: #475467;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const PrimaryButton = styled(Button)`
  border-radius: 8px;
  background: #126fe9;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 12px 18px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export { StylesHeading, StylesSupportingText, PrimaryButton };
